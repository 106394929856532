export const initializeLinksDropdown = () => {
    const dropdownContainers = document.querySelectorAll('.fw-dropdown');

    dropdownContainers.forEach(container => {
        if (!(container instanceof HTMLElement)) {
            return;
        }
        const tabButtons = container.querySelectorAll('.tab-buttons .dropdown-title');
        let activeTab: string = null;

        tabButtons.forEach(btn => {
            if (!(btn instanceof HTMLButtonElement)) {
                return;
            }

            btn.addEventListener('click', () => {
                const tabId = btn.getAttribute('data-tab');
                if (!tabId) return;
                if (activeTab === tabId) {
                    btn.classList.remove('active');
                    btn.setAttribute('aria-selected', 'false');
                    const openContent = container.querySelector(`.dropdown-content[data-tab-content="${tabId}"]`);
                    if (openContent instanceof HTMLElement) {
                        openContent.classList.remove('open');
                    }
                    activeTab = null;
                    return;
                }
                if (activeTab) {
                    const prevBtn = container.querySelector(`.dropdown-title[data-tab="${activeTab}"]`);
                    if (prevBtn instanceof HTMLElement) {
                        prevBtn.classList.remove('active');
                        prevBtn.setAttribute('aria-selected', 'false');
                    }
                    const prevContent = container.querySelector(`.dropdown-content[data-tab-content="${activeTab}"]`);
                    if (prevContent instanceof HTMLElement) {
                        prevContent.classList.remove('open');
                    }
                }
                btn.classList.add('active');
                btn.setAttribute('aria-selected', 'true');
                const contentToOpen = container.querySelector(`.dropdown-content[data-tab-content="${tabId}"]`);
                if (contentToOpen instanceof HTMLElement) {
                    contentToOpen.classList.add('open');
                }
                activeTab = tabId;
            });
        });
    });
};
